import React from "react"
import styled from "@emotion/styled"
// @ts-ignore
import Typewriter from "typewriter-effect"

const Type = styled.div`
  display: flex;
  font-size: 1.7rem;
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
  text-align: center;
`

type TypeProps = {
  texts: Array<string>
  delay: number
  loop: boolean
  typePause: number
  deletePause: number
  loopPause: number
  className?: string
}

const TypeWiter: React.FC<TypeProps> = ({
  texts,
  delay,
  loop,
  typePause,
  deletePause,
  loopPause,
  className,
}) => {
  return (
    <Type className={className}>
      <Typewriter
        options={{
          loop: loop,
        }}
        onInit={(typewriter: any) => {
          typewriter.changeDelay(delay + 10).changeDeleteSpeed(delay)
          let index = 0
          const textLenght = texts.length
          function getCrypticText(): string {
            const crypticPhrases: string[] = [
              "What is Your Duty?, To serve the Emperors will, What is the Emperors Will, That We Fight and Die, what is death, IT IS OUR DUTY!",
              "The walls whisper of the coming storm, yet none listen.",
              "A shadow moves where no light exists, and the truth is rewritten.",
              "He who sees beyond the veil knows the cost of sight.",
              "The dawn is a lie, for the night has only just begun.",
              "She spoke the words, and reality bent to obey.",
              "In the end, the betrayer was the only one who remained true.",
              "A blade that does not cut still draws blood.",
              "What is shattered cannot be unbroken, yet in pieces it speaks clearer.",
              "The void whispers secrets only the dead understand.",
              "To see the truth, one must first unsee the illusion.",
              "A king without a crown is still a ruler, but without a people, he is nothing.",
              "The silent ones know the words that shape the world.",
              "One step forward, yet the past clings like a shroud.",
              "Ash falls, and with it, the last remnants of hope.",
              "The ink of forgotten oaths still stains the hands of the guilty.",
              "A storm does not rage for eternity, but its scars last forever.",
              "They built towers to touch the sky, yet forgot the ground beneath them.",
              "A name spoken in the wrong ear is more dangerous than any blade.",
              "The night itself hungers, but it is the day that deceives.",
              "Power is but a shadow cast by those who fear the dark.",
              "Not all prisons have walls, and not all chains are seen.",
              "When the moon weeps, the stars remember.",
              "Those who chase the sun shall find only their own ashes.",
              "A thousand truths mean nothing if none believe them.",
              "He carved his name in stone, yet the wind still erased it.",
            ]

            const randomIndex = Math.floor(
              Math.random() * crypticPhrases.length
            )
            return crypticPhrases[randomIndex]
          }
          for (const item of texts) {
            const isLast = textLenght === index + 1
            if (isLast) {
              // random ancient language gibberrish cryopted text
              const crypticText = getCrypticText()

              typewriter
                .typeString(crypticText)
                .pauseFor(typePause)
                .deleteChars(crypticText.length)
                .pauseFor(deletePause)
            }
            typewriter
              .typeString(item)
              .pauseFor(typePause)
              .deleteChars(item.length)
              .pauseFor(deletePause)
            index++
          }
          typewriter.deleteAll().pauseFor(loopPause).start()
        }}
      />
    </Type>
  )
}

export default TypeWiter
